import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Container, Image, Describe } from "./styles";

export default function Termos() {
    return (
        <Container>
            <Header />

            <Describe>
                <h1>TERMOS DE USO</h1>
                <p>O presente escrito estabelece e explica a Política de Privacidade da You Go! e/ou plataforma You Go!.
                    A Política de Privacidade é o documento que tange a coleta, utilização, divulgação e transferência de informações,
                    entre as quais as suas informações pessoais, pela You Go!.<br /><br />
                    Ao acessar, usar, utilizar ou navegar pela plataforma, você
                    aceita e concorda com as práticas de gestão de informações descritas nestes termos e caso contrário, caso você não aceite
                    e não concorde com a nossa Política de Privacidade, você deverá navegar pela nossa plataforma.
                    <br /><br />
                    Pelo termo "informações pessoais" acima citado, deverá se compreender informações que podem ser usadas tão somente para
                    identificar ou entrar em contato com você. Informações não pessoais são informações que não permitem nenhuma associação
                    direta com você; estas poderão ser coletadas, utilizadas, transferidas e divulgadas para qualquer finalidade.
                </p>

                <h2>VISÃO GERAL</h2>

                <p>A You Go! é um serviço de locação online que oferece acesso a leitura de revistas, e-books, quadrinhos, dicas de nutrição, revistas técnicas, acesso a
                    audiolivros e videoaulas, que disponibilizados diretamente através da internet para acesso múltiplo – computadores, tablets, smartphones e demais aparelhos
                    através de aplicativos disponibilizados pela You Go!.</p>


                <h2>CONCORDÂNCIA COM OS TERMOS DE USO</h2>

                <p>O serviço You Go! é uma plataforma de leitura, áudios e videoaulas que ao acessar, mediante cadastro prévio, você aceita e concorda com os presentes Termos de Uso;
                    se não concordar, você não deverá assinar o serviço, sendo de sua inteira responsabilidade a sua permanência.</p>


                <h2>ALTERAÇÕES AOS TERMOS DE USO</h2>

                <p>A You Go! poderá alterar esses Termos de Uso periodicamente, inclusive a Política de Privacidade e outros documentos. Essas alterações entrarão em vigor imediatamente,
                    salvo disposição em contrário.<br /></p>

                <p>A You Go! disponibilizará as versões anteriores dos Termos de Uso, publicadas nos últimos doze meses, se ocorrer. Para ter acesso, você deverá entrar em contato
                    com nossa central de relacionamento, solicitando o documento desejado.</p>


                <h2>PRIVACIDADE</h2>

                <p>Informações pessoais que identifiquem os indivíduos estão sujeitas à Política de Privacidade da You Go! e seus termos estão incorporados neste documento, cabendo a você consultá-las.</p>

                <h2>COMUNICAÇÕES</h2>

                <p>Ao se cadastrar no serviço You GO! , você consente em receber comunicados por meios eletrônicos da You Go! relacionados à sua conta (autorizações de pagamento,
                    mudanças de senha ou forma de pagamento, confirmações e outras informações relativas a transações); bem como à publicações de comunicados de serviços da You GO!,
                    de eventos e divulgações promocionais.</p>

                <p>Tal comunicação constituirá parte do seu relacionamento com a You GO!, concordando que quaisquer avisos, contratos, divulgações ou outras comunicações enviadas pela
                    You GO! por via digital satisfazem os requisitos legais de comunicação, incluindo o requisito de que tais comunicações sejam por escrito. Você deverá manter cópias
                    das comunicações eletrônicas imprimindo uma cópia em papel ou salvando uma cópia digital em seus arquivos.</p>

                <h2>LOCAÇÃO DA YOU GO!</h2>

                <p>Acessando a You GO!, você concorda em pagar para ter acesso ao nosso conteúdo pelo valor mensal definido no momento da contratação do seu ingresso, e que poderá sofrer
                    variações para mais ou para menos, conforme definições mercadológicas, de atualização de custos ou promocionais.</p>

                <p>Ao assinar o serviço, a cobrança ocorrerá via cartão de crédito ou débito na sua conta corrente bancária.</p>

                <h2>COBRANÇA DA LOCAÇÃO E FORMAS DE PAGAMENTO</h2>

                <p>No contexto dos presentes Termos de uso, “cobrança” significa lançar um débito, debitar ou compensar o valor a ser quitado, o que for aplicável, da sua forma de pagamento;
                    exceto quando descritos diferentemente, mês ou mensal refere-se ao seu ciclo de cobrança. <br /><br />Em relação ao pagamento do valor mensal de sua locação esta poderá se dar das seguintes formas:
                    via cartão de crédito ou mediante débito em conta corrente. Se você optar pelo cartão de crédito, você autoriza a You GO! a fazer o lançamento mensal do respectivo valor e se optar por
                    débito em conta corrente, você autoriza a You Go! a efetuar o desconto diretamente na conta corrente.
                </p>

                <p>Integrando a You Go!, você nos autoriza a cobrar na forma de pagamento escolhida, o valor equivalente a um mês de locação ao preço atual no ato da cobrança, assim como quaisquer outros valores
                    cobrados em relação à sua utilização da You Go!</p>

                <h2>CANCELAMENTO</h2>

                <p>O consumidor terá o direito de desistir da compra no prazo máximo de 7 (sete) dias corridos, a contar da data de aquisição. A SOLICITAÇÃO DE CANCELAMENTO DEVERÁ SER REALIZADA ATRAVÉS DE UM DE
                    NOSSOS CANAIS DE ATENDIMENTO.<br /><br />

                    AO SOLICITAR O CANCELAMENTO, O CLIENTE DEVERÁ FAZER A DEVOLUÇÃO DOS PRODUTOS NO MESMO LOCAL ONDE REALIZOU A COMPRA, POIS O MESMO ESTÁ INCLUSO NO COMBO APPs + PRODUTOS.<br /><br />

                    A DEVOLUÇÃO PODE OCORRER VIA CORREIOS* SE OS PRODUTOS NÃO ESTIVEREM UTILIZADOS.<br /><br />

                    - OS CUSTOS DO ENVIO DOS PRODUTOS É DE INTEIRA RESPONSABILIDADE DO CLIENTE.<br /><br />

                    SE UM OU MAIS PRODUTOS FOREM UTILIZADOS, FICA TÁCITA A COBRANÇA DOS ITENS, TENDO O VALOR DOS MESMOS DESCONTADOS EM PARCELAS DA SUA ASSINATURA.<br /><br />

                    NÃO ACEITAMOS PRODUTOS DANIFICADOS.<br /><br />

                    O prazo máximo para solicitação do cancelamento do seu combo é de até 7 dias, no qual será feito proporcional ao tempo em que permaneceu com seu acesso ativo em nosso plano de assinatura.</p>

                <h2>CONDIÇÕES LEGAIS DE MAIORIDADE DO LOCADOR</h2>

                <p>Para ter acesso a todo o conteúdo da You GO!, você deve ter no mínimo 18 anos ou ser emancipado, nos termos legais.<br /><br />

                    Menores de 18 anos somente poderão se tornar LOCATÁRIOS mediante participação de um dos pais ou responsáveis, que deverão concordar com os presentes termos de uso.<br /><br />

                    É importante e essencial que, em você sendo menor de idade, não se utilize do “login” de acesso de uma pessoa maior de idade, haja vista que estes poderão ter acesso a conteúdos exclusivos para maiores de idade, sendo, portanto, fora de sua faixa etária.<br /><br />

                    Ressalte-se que a You Go! é adaptada de acordo com a faixa etária do LOCATÁRIO, motivo pelo qual o menor de idade, embora necessite da participação de um dos responsáveis, deve ter sua conta própria para evitar que acesse conteúdos inapropriados. Você concorda em utilizar o serviço
                    fornecido pela You Go! única e exclusivamente como uso pessoal, de forma que você fica proibido de fazer utilização comercial ou pública dos conteúdos existentes na You Go!<br /><br />

                    Durante a locação da You Go!, você recebe uma licença limitada, pessoal e intransferível, para ler, ouvir e ver os conteúdos existentes na You Go!, compreendendo todo o acervo de revistas, quadrinhos,
                    livros, videoaulas e audiobooks e conteúdos congêneres disponíveis da You Go! e com exceção à licença limitada aqui descrita, nenhum outro direito, titularidade ou participação lhe é concedido.<br /><br />

                    O seu “login” será limitado a até quatro aparelhos por vez; no caso de acessos simultâneos de quaisquer outros mais que os quatro aparelhos no mesmo momento, a plataforma arguirá se você deseja desconectar um dos conectados pra permitir o acesso pelos outros.</p>
                <h2>CONDIÇÕES LEGAIS DE MAIORIDADE DO LOCADOR</h2>

                <p>Para ter acesso a todo o conteúdo da You GO!, você deve ter no mínimo 18 anos ou ser emancipado, nos termos legais.<br /><br />

                    Menores de 18 anos somente poderão se tornar LOCATÁRIOS mediante participação de um dos pais ou responsáveis, que deverão concordar com os presentes termos de uso.<br /><br />

                    É importante e essencial que, em você sendo menor de idade, não se utilize do “login” de acesso de uma pessoa maior de idade, haja vista que estes poderão ter acesso a conteúdos exclusivos para maiores de idade, sendo, portanto, fora de sua faixa etária.<br /><br />

                    Ressalte-se que a You Go! é adaptada de acordo com a faixa etária do LOCATÁRIO, motivo pelo qual o menor de idade, embora necessite da participação de um dos responsáveis, deve ter sua conta própria para evitar que acesse conteúdos inapropriados. Você concorda em utilizar o serviço fornecido pela You Go! única e exclusivamente como uso pessoal, de forma que você fica proibido de fazer utilização comercial ou pública dos conteúdos existentes na You Go!<br /><br />

                    Durante a locação da You Go!, você recebe uma licença limitada, pessoal e intransferível, para ler, ouvir e ver os conteúdos existentes na You Go!, compreendendo todo o acervo de revistas, quadrinhos, livros, videoaulas e audiobooks e conteúdos congêneres disponíveis da You Go! e com exceção à licença limitada aqui descrita, nenhum outro direito, titularidade ou participação lhe é concedido.<br /><br />

                    O seu “login” será limitado a até quatro aparelhos por vez; no caso de acessos simultâneos de quaisquer outros mais que os quatro aparelhos no mesmo momento, a plataforma arguirá se você deseja desconectar um dos conectados pra permitir o acesso pelos outros.</p><br />

                <h2>DOS CONTEÚDOS DISPONÍVEIS</h2>
                <p>A You Go! atualiza seus conteúdos continuadamente e além disso, diferentes aspectos do serviço são testados rotineiramente, incluindo o site, interfaces de usuário, níveis de serviço, planos, recursos promocionais, disponibilidade e velocidades de acesso dos conteúdos.<br /><br />

                    Você concorda e aceita que a You Go! poderá conduzir testes e inclusive incluir ou excluir você destes testes sem prévio aviso.<br /><br />

                    A You Go! se reserva ao direito, praticado inteiramente conforme critérios próprios, de alterar periodicamente e sem aviso prévio a forma de como o serviço é oferecido e operado.<br /><br />

                    Você concorda em usar os serviços da You Go!, incluindo todos os recursos e funcionalidades do mesmo, segundo todas as leis, regulamentos e códigos aplicáveis, assim como outras restrições de uso ou conteúdo previstas nas mesmas.<br /><br />

                    Você concorda em não arquivar, tirar fotos das telas, usar “print screen”, baixar (exceto o cachê necessário para uso pessoal), reproduzir, distribuir, modificar, exibir, publicar, licenciar ou criar trabalhos derivados, colocar à venda ou utilizar ( exceto das formas expressamente autorizadas por esses Termos de Uso) sobre todo e qualquer conteúdo e/ou as informações contidas ou obtidas do ou por intermédio da You Go! sem a autorização expressa e por escrito da You Go! e de seus licenciadores.<br /><br />

                    Você também concorda em não: evadir, remover, alterar, desativar, degradar, ou adulterar quaisquer das proteções de conteúdo do serviço You Go!, usar qualquer robô, spider, crawler, bots, scraper ou outras formas automatizadas para acessar os serviços da You Go!, descompilar, executar engenharia reversa ou desmembrar qualquer software ou outros produtos ou processos acessíveis inserir qualquer código ou produto, manipular o conteúdo de serviço de qualquer forma ou usar métodos de data mining, coleta de dados ou extração de dados.<br /><br />

                    Além disso, você concorda em não fazer upload, publicar, enviar por e-mail, comunicar ou transmitir de qualquer forma qualquer material designado para interromper, destruir ou limitar a funcionalidade de qualquer software, hardware, ou equipamento de telecomunicações associado à You Go! incluindo vírus de software, código, arquivos ou programas.<br /><br />

                    A qualidade de imagem dos conteúdos pode variar de aparelho para aparelho, e pode ser afetada por diversos fatores, incluindo sua localização, a largura de banda disponível e a velocidade da sua conexão com a Internet. A disponibilidade de alta definição (HD) e ultra alta definição (Ultra HD) está sujeita a seu serviço de Internet e à funcionalidade do aparelho usado.<br /><br />

                    Nem todo conteúdo está disponível em HD ou Ultra HD e recomendamos uma velocidade mínima de download de 5.0 Mbps para receber conteúdo em alta resolução.<br /><br />

                    Você se responsabiliza por todas as tarifas de acesso à Internet. Consulte seu provedor de internet para obter informações sobre os custos de utilização de dados.<br /><br />

                    A You Go! não faz afirmações ou garantias com respeito à qualidade da imagem na sua tela.<br /><br />
                </p>

                <h2>DA You Go! E DAS CONDIÇÕES DE USO</h2>
                <p>O LOCATÁRIO poderá fazer o acesso ao conteúdo através da rede mundial de computadores – internet – pelo computador pessoal, smartphones, tablets e outros dispositivos portáteis compatíveis.<br /><br />

                    Os apps do combo estão disponíveis para download na “GooglePlay”, na “AppleStore” ou de outros participantes, foram projetados para permitir a leitura online de conteúdos desta para aparelhos compatíveis com os sistemas “iOS” e “Android”, sem direito ao “download”.<br /><br />

                    Esses softwares poderam variar conforme o aparelho e mídia e a funcionalidade também poderam variar segundo o aparelho utilizado, de forma que a You Go! não se responsabiliza pela não compatibilidade de seu aparelho, sendo de inteira responsabilidade do LOCATÁRIO, que concorda que não haverá reembolso ou direito a eventuais indenizações em tais casos.<br /><br />

                    Caso seu aparelho de acesso ao combo de apps da You Go! seja vendido, perdido ou roubado, é sua obrigação desativar este aparelho de acesso dos apps da You Go!<br /><br />

                    Se você não encerrar a sessão ou desativar o aparelho, outros usuários poderão acessar os serviços da You GO! através da sua conta e ganhar acesso a determinadas informações em sua conta.<br /><br />

                    A You Go! poderá encerrar ou restringir seu uso do serviço sem indenização ou aviso prévio se houver suspeita de (i) infração de qualquer dos presentes Termos de uso ou (ii) envolvimento com uso ilegal ou inadequado do serviço.<br /><br />
                </p>

                <h2>SUA CONTA NA You Go!, TITULARIDADE E SENHAS.</h2>
                <p>Criando a sua conta na You Go!, você será o titular que se submete aos presentes Termos de Uso.<br /><br />

                    Você terá acesso e controle sobre sua conta na própria You Go! e deverá assegurar, sob sua responsabilidade, a veracidade de todos os dados informados relacionados a seus dados pessoais, tais como documentos de identificação, residência, etc.<br /><br />

                    Para exercer tal controle, você precisará criar uma senha, para mantê-la de forma pessoal e exclusiva, não devendo informar e/ou a revelar para ninguém.<br /><br />

                    Se você acreditar que sua conta está sendo utilizada por outra pessoa, mude imediatamente sua senha entrando em contato com o nosso chat através do site: www.yougoapp.com.br.<br /><br />

                    Exerça cautela com respeito a comunicados que solicitem o envio de dados de cartão de crédito ou outras informações sobre sua conta. Responder a comunicados dessa natureza com informações pessoais poderá resultar no roubo de seus dados pessoais.<br /><br />

                    A You Go! reserva o direito de suspender qualquer conta a qualquer momento, com ou sem aviso prévio ao titular da conta, para proteger a si mesma e empresas associadas de atividades suspeitas de serem fraudulentas.<br /><br />

                    A You GO! não será obrigada a reembolsar ou dar descontos a LOCATÁRIOS cujas contas foram suspensas.</p><br /><br />

                <h2>ISENÇÂO DE GARANTIA DE FUNCIONALIDADE PERMANENTE E ININTERRUPTA</h2>
                <p>A You Go! disponibiliza todo o seu acervo de conteúdos, aplicativos, softwares, tecnologias e demais recursos, conforme estado atual do acesso, não havendo garantia de que o serviço estará integralmente funcionando 24 horas por dia ou que seu serviço será contínuo e livre de erros;
                    você concorda, portanto, que poderão ocorrer falhas de qualquer tipo na You Go!.
                </p>

                <h2>PROPRIEDADE INTELECTUAL, DIREITOS AUTORAIS E MARCAS REGISTRADAS</h2>
                <p>A You Go! atua em estrita obediência e respeito à Lei n. 9.610/98 (Lei dos Direitos Autorais), de forma que todo o acervo oferecido é fornecido e pertencente às diversas editoras participantes da You Go! e são protegidos por direitos autorais, segredos comerciais e outras leis e tratados de propriedade intelectual.<br /><br />

                    Da mesma forma, os softwares desenvolvidos pela ou para a You Go!, que possibilitam o acesso ao conteúdo através de dispositivos móveis, como tablets e smartphones, é protegido pela Lei nº 9.609/98, sendo vedada sua utilização para fins diversos do aqui previsto, ou seja, acesso e leitura do conteúdo disponível no acervo dos apps licenciados do combo da You Go! ficando expressamente proibido sua reprodução, no todo ou em partes; venda; locação ou cessão.<br /><br />

                    Se você tiver conhecimento ou dúvida envolvendo infração de direitos autorais, deverá imediatamente nos enviar informações através do atendimentoaocliente@177.154.191.138 ou em nosso site.</p>
                <br /><br />

                <h2>APLICATIVOS</h2>
                <p>Você poderá encontrar aplicativos de terceiros (incluindo, mas não limitando-se a websites, widgets, software ou outros utilitários de software) (“aplicativo(s)”) que interagem com a plataforma You GO!.<br /><br />

                    Esses aplicativos podem importar dados relacionados à sua conta You Go! e atividade e obter dados de você. Esses aplicativos são fornecidos somente para sua conveniência e a You Go! não é responsável por eles. Esses aplicativos são de propriedade e operados por terceiros e não relacionados nem patrocinados pela You GO!, de forma que a utilização de um aplicativo fica a seu total critério e risco.<br /><br />

                    A You Go! terá liberdade de usar quaisquer comentários, informações, ideias, conceitos, opiniões, técnicas ou quaisquer outros materiais contidos em qualquer comunicação que você nos envie, incluindo respostas a questionários, que incluam nosso site e suas interfaces de usuário, em caráter global e perpétuo, sem remuneração, reconhecimento ou pagamento a você por qualquer motivo, incluindo mas não limitando-se a desenvolvimento, fabricação e comercialização de produtos e criação, modificação ou aprimoramento da nossa plataforma You Go!<br /><br />

                    Além disso, você concorda em não reivindicar quaisquer “direitos morais” a You Go! em razão de tais manifestações, até a extensão permitida pelas leis aplicáveis.<br /><br />

                    Desta forma, se você desejar enviar quaisquer materiais ou ideias não solicitados, faça-o com o entendimento de que não será devido qualquer pagamento de qualquer natureza a você e de que você está concordando em isentar a You Go! e suas afiliadas de qualquer reclamação ou processo relativo ao uso de tais materiais e ideias, mesmo que o material ou ideia seja usado e seja substancialmente semelhante ao material ou ideia enviado por você.<br /><br />
                </p>

                <h2>ATENDIMENTO AO CLIENTE</h2>
                <p>Para obter mais informações sobre a You Go! e seus recursos, ou se precisar de ajuda com sua conta, acesse a Central de Ajuda através do e-mail atendimentoaocliente@177.154.191.138.<br /><br />

                    No caso de quaisquer conflitos entre esses Termos de uso e informações fornecidas na Central de Ajuda, esses Termos de uso prevalecerão.<br /><br />

                    Belém, 10 de janeiro de 2023.<br /><br />
                </p>

            </Describe>

            <Footer />
        </Container>
    );
};