import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Container, Image, Describe } from "./styles";

export default function Privacidade() {
    return (
        <Container>
            <Header />

            <Describe>
                <h1>Termos de Serviço e Privacidade</h1>
                <p>O presente escrito estabelece e explica a Política de Privacidade da You Go! e/ou plataforma You Go!.
                    A Política de Privacidade é o documento que tange a coleta, utilização, divulgação e transferência de informações,
                    entre as quais as suas informações pessoais, pela You Go!. <br /><br />
                    Ao acessar, usar, utilizar ou navegar pela plataforma, você
                    aceita e concorda com as práticas de gestão de informações descritas nestes termos e caso contrário, caso você não aceite
                    e não concorde com a nossa Política de Privacidade, você deverá navegar pela nossa plataforma.
                    <br /><br />
                    Pelo termo "informações pessoais" acima citado, deverá se compreender informações que podem ser usadas tão somente para
                    identificar ou entrar em contato com você. Informações não pessoais são informações que não permitem nenhuma associação
                    direta com você; estas poderão ser coletadas, utilizadas, transferidas e divulgadas para qualquer finalidade.
                </p>

                <h2>COLETA DE INFORMAÇÕES</h2>

                <p>Sobre a coleta de informações você precisa saber que a You Go! recebe e armazena informações suas, como:</p>
                <p>
                    I - Informações que você fornece, a exemplo dos dados cadastrais (nome, endereço eletrônico e endereço residencial, forma de pagamento, número do telefone, etc.);<br /><br />

                    II - Informações coletadas quando você decide por classificar os conteúdos, escolhe suas preferências na sua conta ou fornece informações de outras maneiras, seja por intermédio da
                    plataforma ou não;<br /><br />

                    III - Informações sobre você e suas interações com a You Go! e a publicidade desta, seu uso de serviço,
                    aplicativo, sites, ferramentas e serviços de atendimento ao cliente da You Go!, e ainda informações sobre seus aparelhos de
                    acesso - computadores, e outros aparelhos utilizados para acessar a nossa plataforma - tais informações poderão incluir:
                    suas atividades na You Go!, como os conteúdos selecionados, o histórico de conteúdos lidos e pesquisas, tempo utilizado em
                    cada cesso, detalhes sobre seus usos, seus contatos com o nosso serviço de atendimento, inclusive a data, hora e o motivo,
                    o conteúdo das conversas pelo chat online (inclusive salvar os dados fornecidos), IDs ou indentificadores únicos MAC ADRESS
                    dos aparelhos, características de aparelhos e software, informações sobre a conexão, estatíticas sobre visualização de página,
                    URL's de origem e destino, dados de anúncios, endereços IP's, e dados padrão de logs da internet e da plataforma e também
                    informações geradas pelo uso de cookies;<br /><br />

                    IV - Informações da sua conta em redes sociais também serão coletadas, se você
                    optar por acessar a plataforma You Go! através das suas contas de redes sociais como: facebook, instagram, Snapchat, Twitter
                    ou qualquer outra, e esta plataforma irá obter informações de sua conta de rede social conforme tratado adiante;<br /><br />

                    V - A You Go! poderá complementar essas informações através de outras fontes, tanto de fornecedores de dados online como offline, informações
                    estas que poderão incluir dados demográficos, geográficos, e dos seus comportamentos na internet - a You Go! poderá utilizar tais
                    informações para quaisquer objetivos de acordo com esta Política de Privacidade, assim como analisar e entender os nossos locatários
                    atuais, antigos e potenciais, melhorar o serviço, inclusive a interface do usuário, otimizar a transmissão, seleção de conteúdo,
                    publicidade, e marketing e para proteger a You Go! e seus locatários de fraudes e acessos não autorizados.</p>

                <p>A You Go! coleta as informações de diversos modos, inclusive quando você navega, acessa, distribui, informa, compartilha, entra no site e/ou interage com o serviço de atendimento ao cliente ou participa de pesquisas ou promoções de marketing da You Go! ou de terceiros vinculados ou não.</p>

                <h2>USO DE INFORMAÇÕES</h2>

                <p>A plataforma utiliza todas as informações coletadas para proporcionar, ponderar, administrar, aperfeiçoar, e personalizar nossos serviços e esforços de marketing para você e outros, inclusive processar sua locação, pedidos e pagamentos, e para nos comunicarmos com você sobre esses e outros assuntos.<br /><br />A You Go! poderá utilizar tais informações para:</p>

                <p>I - Definir sua localização geográfica geral, propiciar conteúdo traduzido, recomendações de conteúdos do catálogo que consideramos que você gostaria de ler, especificar o seu provedor de serviços da internet, auxiliar nossa equipe a responder de forma mais célere e eficiente às suas dúvidas e solicitações;<br /><br />
                    II - Atuar de forma preventiva e repressiva para a detecção de atividades possivelmente proibidas ou ilegais, incluindo atividades fraudulentas, aplicando, consequentemente, nossos Termos de Uso, Políticas de Privacidades, cookies, etc.;<br /><br />
                    III - Enviar e-mails, notificações e/ou outros comunicados e anúncios sobre o serviço You Go!, incluindo boletins informativos, mensagens sobre recursos da You Go!, ofertas especiais, promoções e pesquisas.</p>

                <p>O rol acima é meramente exemplificativo e não taxativo; isto quer dizer que poderão haver outros casos, experiências e acontecimentos além destes.<br /><br />
                A You Go! poderá, de forma periódica, formar parcerias com outras empresas cujos produtos acreditamos que poderão ser do seu interesse, podendo enviar comunicados sobre promoções e/ou transmitir publicidade online em nome de empresa parceira, de acordo com os preceitos legais. Você poderá eventualmente cancelar o recebimento de avisos de marketing, notificações push e outros meios de publicidade acessando sua área em nossa plataforma.<br/><br/>
                A You Go! poderá ainda utilizar informações de forma agregada ou anônima, como na classificação dos conteúdos do catálogo, comportamento de consumo e avaliações realizadas a fim de satisfazer você e demais LOCATÁRIOS. Tais informações não descritas explicitamente na Política de Privacidade.</p>

                <h2>DIVULGAÇÃO DE INFORMAÇÕES</h2>

                <p>A You Go! poderá se utilizar de outras empresa, agentes, ou terceirizados para fornecer serviços em nome da plataforma ou ajudar no fornecimento de serviços destinados a você, a exemplos de empresas terceirizadas para fornecer infraestrutura e serviços de tecnologia e informática para tornar a PLATAFORMA mais segura, minimizando os riscos de fraudes com relação a transações feitas por cartões de crédito por você, bem como empresas para fazer pesquisa de mercado, dentre outras. Essas empresas ou agentes terceirizados poderão receber informações suas, pessoais ou de outra natureza, podendo serem utilizadas única e exclusivamente para os serviços contratados da You Go!, sendo vedado qualquer outro tipo de uso.<br /><br />
                A You Go! poderá oferecer promoções ou campanhas conjuntas que, para efeito de participação, determinem que informações pessoais (nome, dados e quaisquer outras informações pessoais) sejam compartilhadas com estes parceiros. Aderindo à promoção você consente em compartilhar suas informações pessoais, que serão utilizadas conforme as práticas de privacidade destes terceiros.</p>
                <p>Para o atendimento e proteção da PLATAFORMA e de você LOCATÁRIO, a You Go! poderá divulgar e utilizar informações pessoais de outras naturezas para atender a determinações judiciais e de órgãos públicos, assim como para atender à legislação vigente ou outras posteriormente promulgadas; fazer valer as regras da PLATAFORMA com base nos respectivo termos; investigar, detectar, prevenir e remediar atividades ilegais ou suspeitas, assim como fraudes de pagamentos, e também para resolver problemas técnicos e/ou de segurança; etc.</p>
                <p>As informações publicadas por você, em suas redes sociais e demais meios de comunicação, em relação à You Go!, são de sua inteira responsabilidade, de forma que esta não se responsabiliza pelo uso de terceiros de tais informações.</p>
                <p>Se você compartilhar ou permitir que terceiros tenham entrada em sua conta na PLATAFORMA, estes poderão obter suas informações pessoais, bem como verificar seus conteúdos lidos ou ainda ter acesso às suas informações, como seus dados bancários, dentre outras, de forma que a You Go! também não se responsabiliza, em tais casos, pelas suas informações.</p>

                <h2>POLÍTICA DE COOKIES</h2>

                <p>Utilizamos "cookies" para obter informações e outras tecnologias de monitoramento, suporte e operação de publicidade.</p>

                <h2>EDIÇÃO DE INFORMAÇÕES</h2>

                <p>Na PLATAFORMA você poderá acessar suas definições para edição sobre uma série de informações acerca de sua conta, inclusive informações de contato (telefones, endereço e e mail) e suas preferências de comunicação.<br/><br/>
                Se você tiver algum problema com suas informações, entre em contato com atendimentoaocliente@177.154.191.138 a fim de relatar o ocorrido.</p>

                <h2>FERRAMENTAS DE USO</h2>

                <p>A You Go! é disponibilizada como plataforma para acessos por computadores, telefones e tablets e assemelhados, e outros aparelhos cujas políticas de privacidade e termos de uso podem ser diferentes e indenpendentes das nossas.<br/><br/>
                É interessante que você leia os termos destes aparelhos, porque a You Go! não se responsabiliza como tais sites, provedores, e plataformas tratam as suas informações.</p>

                <h2>IDADE MÍNIMA PARA LOCATÁRIOS E USO POR MENOR DE IDADE</h2>

                <p>Para ser locatário da You Go! você deve ser maior de idade, o que corresponde a ser maior de 18 anos excepcionalmente você poderá ser locatário com menos de 18 anos, caso você seja emancipado judicialmente.<br/><br/>
                As crianças e adolescentes, menores de 18 anos, somente poderão utilizar a You Go! pela conta de seu responsável, mediante e aprovação deste, que arcará com todas e quaisquer responsabilidades;</p>

                <h2>ATUALIZAÇÕES NA POLÍTICA DE PRIVACIDADE</h2>

                <p>A You Go! poderá realizar quaisquer atualizações e/ou alterações nesta política de privacidade e na política de cookies a qualquer momento.<br/><br/>
                Tais atualizações entrarão em vigor, aplicando-se a você, imediatamente. Leia as atualizações. Se você não concordar com elas, você não deverá continuar na PLATAFORMA: sua permanência significa que você concorda com elas.<br/><br />
                Para ter acesso às Políticas de Privacidade e demais termos, anteriores aos atuais, entre em contato com o serviço de atendimento ao cliente.</p>

                <h2>CONTATO COM A YOU GO!</h2>

                <p>Se você tiver dificuldades com a sua conta, utilize o e-mail atendimentoaocliente@177.154.191.138 para tirar suas dúvidas.
                <br/><br /> Em caso de dúvidas ou de não ter entendido determinada cláusula dos termos acima mencionados ou desta Política de Privacidade, entre em contato conosco para que possamos resolver seus problemas.</p>
                <p>Para obter mais informações sobre a You Go! e seus recursos, ou se precisar de ajuda com a sua conta, acesse o CHAT ou envie um e-mail para atendimentoaocliente@177.154.191.138.</p>
                <p>Belém, 10 de janeiro de 2023.</p>

            </Describe>

            <Footer />
        </Container>
    );
};